import React from "react";
import "./App.css";
import ReactChessboard from "./Test";
import Test from "./Test";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <b>Ovečka a vlci</b>
        </p>
        <ReactChessboard />
      </header>
    </div>
  );
}

export default App;
