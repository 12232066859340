import { Piece, Square } from "chess.js";
import { useEffect, useState } from "react";
import { Chessboard } from "react-chessboard";
enum WolfType {
  Wolf1 = "Wolf1",
  Wolf2 = "Wolf2",
  Wolf3 = "Wolf3",
  Wolf4 = "Wolf4",
}

enum CurrentMove {
  Wolf = "Wolf",
  Sheep = "Sheep",
}

export default function ReactChessboard() {
  const [currentMove, setCurrentMove] = useState(CurrentMove.Sheep);
  const [message, setMessage] = useState("");
  const [sheep, setSheep] = useState(["d1", "wP"]);
  const [wolf1, setWolf1] = useState(["a8", "bP"]);
  const [wolf2, setWolf2] = useState(["c8", "bP"]);
  const [wolf3, setWolf3] = useState(["e8", "bP"]);
  const [wolf4, setWolf4] = useState(["g8", "bP"]);

  useEffect(() => {
    checkIfWolvesWon();
  }, [sheep, wolf1, wolf2, wolf3, wolf4]);

  const findWolfBasedOnSquare = (square: any) => {
    if (square === wolf1[0]) {
      return WolfType.Wolf1;
    } else if (square === wolf2[0]) {
      return WolfType.Wolf2;
    } else if (square === wolf3[0]) {
      return WolfType.Wolf3;
    } else if (square === wolf4[0]) {
      return WolfType.Wolf4;
    }
  };
  const handleWolfMove = (from: any, to: any, who: any) => {
    const wolf = findWolfBasedOnSquare(from);
    const origColumn = from.charCodeAt(0);
    const origRow = parseInt(from[1]);
    const destColumn = to.charCodeAt(0);
    const destRow = parseInt(to[1]);
    const columnDiff = Math.abs(origColumn - destColumn);
    const rowDiff = origRow - destRow;
    if (columnDiff === 1 && rowDiff === 1) {
      if (wolf === WolfType.Wolf1) {
        setWolf1([to, who]);
      } else if (wolf === WolfType.Wolf2) {
        setWolf2([to, who]);
      } else if (wolf === WolfType.Wolf3) {
        setWolf3([to, who]);
      } else if (wolf === WolfType.Wolf4) {
        setWolf4([to, who]);
      }
      setCurrentMove(CurrentMove.Sheep);
    }

    return true;
  };

  const handleSheepMove = (from: any, to: any, who: any) => {
    const origColumn = from.charCodeAt(0);
    const origRow = parseInt(from[1]);
    const destColumn = to.charCodeAt(0);
    const destRow = parseInt(to[1]);
    const columnDiff = Math.abs(origColumn - destColumn);
    const rowDiff = Math.abs(origRow - destRow);
    if (columnDiff === 1 && rowDiff === 1) {
      setSheep([to, who]);
      setCurrentMove(CurrentMove.Wolf);
    }
    return true;
  };

  const handleMove = (from: any, to: any, who: any) => {
    if (checkIfFieldIsOccupied(to)) {
      alert("Field is occupied");
      return false;
    }
    if (who === "bP" && currentMove === CurrentMove.Wolf) {
      handleWolfMove(from, to, who);
    } else if (who === "wP" && currentMove === CurrentMove.Sheep) {
      handleSheepMove(from, to, who);
    }
    return true;
  };

  const convertPositionToNumber = (position: any) => {
    const column = position.charCodeAt(0) - 96;
    const row = parseInt(position[1]);
    return [column, row];
  };

  const convertNumberToPosition = (column: any, row: any) => {
    const columnLetter = String.fromCharCode(column + 96);
    return columnLetter + row;
  };

  const checkIfFieldIsOccupied = (position: any) => {
    const [column, row] = convertPositionToNumber(position);
    const wolf1Position = convertPositionToNumber(wolf1[0]);
    const wolf2Position = convertPositionToNumber(wolf2[0]);
    const wolf3Position = convertPositionToNumber(wolf3[0]);
    const wolf4Position = convertPositionToNumber(wolf4[0]);
    if (wolf1Position[0] === column && wolf1Position[1] === row) {
      return true;
    } else if (wolf2Position[0] === column && wolf2Position[1] === row) {
      return true;
    } else if (wolf3Position[0] === column && wolf3Position[1] === row) {
      return true;
    } else if (wolf4Position[0] === column && wolf4Position[1] === row) {
      return true;
    }
    return false;
  };

  const checkIfWolvesWon = () => {
    //get current row and column of sheep
    const sheepColumn = sheep[0].charCodeAt(0) - 96;
    const sheepRow = parseInt(sheep[0][1]);
    if (sheepRow === 1 && sheepColumn !== 8) {
      //check bottom row without corner
      if (
        checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn + 1, 2)) &&
        checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn - 1, 2))
      ) {
        restartGame();
      }
    } else if (sheepRow === 1 && sheepColumn === 8) {
      //check bottom right corner
      if (checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn - 1, 2))) {
        restartGame();
      }
    } else if (sheepRow === 1 && sheepColumn === 1) {
      //check top row without corner
      if (
        checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn + 1, 7)) &&
        checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn - 1, 7))
      ) {
        restartGame();
      }
    } else if (sheepRow === 1 && sheepColumn === 1) {
      //check top left corner
      if (checkIfFieldIsOccupied(convertNumberToPosition(sheepColumn + 1, 2))) {
        restartGame();
      }
    } else if (sheepRow !== 8 && sheepColumn === 1) {
      //check left column without corner
      if (
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn + 1, sheepRow - 1)
        ) &&
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn + 1, sheepRow + 1)
        )
      ) {
        restartGame();
      }
    } else if (sheepRow !== 8 && sheepColumn === 8) {
      //check right column without corner
      if (
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn - 1, sheepRow - 1)
        ) &&
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn - 1, sheepRow + 1)
        )
      ) {
        restartGame();
      }
    } else if (sheepRow === 8 && sheepColumn === 1) {
      //check top left corner
      if (
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn + 1, sheepRow - 1)
        )
      ) {
        restartGame();
      }
    } else {
      //check all other fields
      if (
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn + 1, sheepRow - 1)
        ) &&
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn + 1, sheepRow + 1)
        ) &&
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn - 1, sheepRow - 1)
        ) &&
        checkIfFieldIsOccupied(
          convertNumberToPosition(sheepColumn - 1, sheepRow + 1)
        )
      ) {
        restartGame();
      }
    }
  };

  const restartGame = () => {
    setMessage("Wolves won!");
    //set timeout for 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
    setSheep(["d1", "wP"]);
    setWolf1(["a8", "bP"]);
    setWolf2(["c8", "bP"]);
    setWolf3(["e8", "bP"]);
    setWolf4(["g8", "bP"]);
    setCurrentMove(CurrentMove.Sheep);
  };

  const position = {
    [sheep[0]]: sheep[1],
    [wolf1[0]]: wolf1[1],
    [wolf2[0]]: wolf2[1],
    [wolf3[0]]: wolf3[1],
    [wolf4[0]]: wolf4[1],
  };

  return (
    <>
      <div style={{ width: "500px", height: "500px", marginBottom: "16px" }}>
        <Chessboard
          id="BasicBoard"
          position={position}
          onPieceDrop={handleMove}
        />
      </div>
      <b>{message}</b>
    </>
  );
}
